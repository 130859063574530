.footer {
  margin-bottom: 0px !important;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 121, 59, 1) 68%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
  color: white;
  text-align: right;
}
.footer .links ul {
  list-style-type: none;
}
.footer links li a {
  color: white;
  transition: color 0.2s;
}
.footer .links li a:hover {
  text-decoration: none;
  color: #4180cb;
}
.footer .about-company,
.footer .links,
.footer .location {
  margin: auto;
  text-align: center;
}
.footer .about-company i {
  font-size: 25px;
}
.footer .about-company a {
  color: white;
  transition: color 0.2s;
}
.footer .about-company a:hover {
  color: #4180cb;
}

.location i {
  font-size: 18px;
}

.location copyright p {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.btn-floating {
  /* background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 121, 59, 1) 68%,
    rgba(0, 212, 255, 1) 100%
  ); */
  right: 0;
  bottom: 0;
  position: fixed;
  /* display: none; */
}

.btn-floating:hover {
  font-size: x-large;
}
.contentFooter {
  direction: ltr;
}
.contentFooter p a {
  direction: ltr;
  color: white;
}
.footer #listOfPages {
  padding-right: 5% !important;
}
@media only screen and (max-width: 600px) {
  .footer {
    background: linear-gradient(90deg, #020024, #09793b 68%) !important;
  }
  .about-company,
  .links,
  .location {
    text-align: center;
  }
  .location {
    margin-top: 15px;
  }

  #myLiLinks {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer #listOfPages {
    padding-right: 0% !important;
  }
}
