.left-side-menu {
  z-index: 999;
  height: 100vh;
  width: 70%;
  left: 0;
  top: -30px;
  position: fixed;
  .left-side-menu-wrapper {
    height: 100%;
    width: 100%;
    background-color: #fff;
  }
  table {
    margin: auto;
    margin-bottom: 100px;
  }

  .ordersSummaryTable td,
  .ordersSummaryTable th {
    border: 1px solid #dddddd;
    font-size: 0.7rem !important;
    font-weight: 300 !important;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }

  .tableOfOrders th {
    border: 1px solid #dbc6c6;
  }
  .boxDiv {
    border-style: groove;
    border-radius: 5px !important;
  }

  .boxDiv span {
    position: relative;
    margin: auto;
    color: green;
  }
  .load-deliveries {
    height: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
