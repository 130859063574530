.container {
  direction: rtl;
  text-align: right;
}
.quantity {
  padding: 0;
  margin-right: 15px;
  padding-right: 15px;
  background-color: #f6f6f6;
  position: relative;
  width: max-content;
  overflow: hidden;
  display: contents;
  padding: 0;
  margin: auto;
}
.buttonOfProperty {
  margin: 0 5px;
  cursor: pointer;
  display: block;
  padding: 1px 7px 3px;
  text-align: center;
  border: 1px solid #ddd;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  border-radius: 5px;
}
.buttonOfProperty:hover,
.selectedTextDecoration {
  background-color: #d0e4f5;
}
.quantity input {
  outline: 0;
}
.propertyOfProduct .btn {
  font-size: 0 !important;
}
.fa-trash:before {
  content: "\f1f8";
  /* font-size: large var(--webkit-background-size); */
}
.quantity input.qty {
  position: relative;
  border: 0;
  width: max-content;
  height: 40px;
  padding: 0;
  text-align: center;
  font-weight: 400;
  font-size: 15x;
  border-radius: 0;
  background-clip: padding-box;
}

.quantity .minus,
.quantity .plus {
  line-height: 0;
  background-clip: padding-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  --webkit-background-size: 6px 30px;
  --moz-background-size: 6px 30px;
  color: #bbb;
  font-size: 20px;
  position: absolute;
  height: 50%;
  border: 0;
  right: 0;
  padding: 0;
  width: 25px;
  z-index: 3;
}

.quantity .minus:hover,
.quantity .plus:hover {
  background-color: #dad8da;
}

.quantity .minus {
  bottom: 0;
}
.rounded-button {
  vertical-align: baseline;
}
.shopping-cart {
  margin-top: 20px;
}
.btn {
  padding: 0.84rem 1.14rem !important;
}
.md-form .prefix ~ label {
  width: 100%;
  margin-left: 0 !important;
  text-align: right;
}
.md-form .prefix {
  width: 100%;
  text-align: left;
}
form {
  width: 100%;
  margin: auto;
  text-align: center;
}
.propertyOfProduct {
  /* padding: 15px; */
  margin: auto;
}

.myProduct {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dinamicTrashBtn i {
  font-size: large;
  margin: auto;
}
.myFaFaTrash {
  justify-content: center;
  /* display: flex !important; */
  line-height: 0 !important;
}
#myForm {
  padding: 0;
  border: none;
}
/* #myForm label{
   color: black;
} */
textarea.md-textarea.form-control:focus {
  border: 1px solid #4285f4;
  padding: 5px;
}

.activeProduct {
  /* box-shadow: rgba(0, 0, 0, 0.5) 5px 5px 5px 5px;
   */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  border-radius: 5px;
}
.activeProduct input {
  font-weight: bold !important;
  font-size: large;
}
.activeTrashBtn {
  /* display: inline-block !important; */
  visibility: visible !important;
  transition: visibility 0.5s linear 200ms, opacity 500ms;

  /* opacity: 1 !important;
  animation-duration: 300ms; */
}

.dinamicTrashBtn {
  width: 55px !important;
  height: 60px !important;
  margin: 0 !important;
}
.btn-outline-danger {
  margin: auto !important;
}

#reservationOfProducts {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
#productsTable {
  text-align: center;
}
#productsTable tr:nth-child(odd) {
  background-color: white !important;
}

#productsTable th {
  vertical-align: middle;
  text-align: center;
  color: #fff;
  background-color: #343a40;
}
#productsTable td {
  text-align: center;
  vertical-align: middle;
}
#productsTable .myCell {
  border: 1px solid rgb(130, 119, 23);
}
.imgCell {
  background-color: white !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}
#dataForMail {
  display: none;
}

@media only screen and (max-width: 780) {
  .propertyOfProduct {
    margin: auto !important;
  }
  .quantity {
    width: 100% !important;
    display: inline-block !important;
    padding: 0;
    margin: auto;
  }

  #multiplySign {
    display: block !important;
    margin: auto;
  }
  .dinamicTrashBtn {
    width: 50px;
  }
  #productsTable {
    padding: 5px !important;
  }
}
