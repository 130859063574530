@import "src/vars.scss";
// @import "src/anim";

.rounded-button {
  transition: all 0.1s;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: none;
  box-shadow: 2px 2px 5px $medium-shadow;
  background: white;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;

  &.primary {
    background: $user-gradient;
    color: white;
    box-shadow: 2px 2px 5px $light-shadow;
  }

  // color: $text-normal;
  i {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  table & i {
    color: $text-light;
  }

  &:focus {
    box-shadow: 2px 2px 5px $hard-shadow;
    &:not(.primary) > i {
      color: $text-normal;
    }
  }
  &:hover:not([disabled]) {
    box-shadow: 2px 2px 5px $hard-shadow;
    transform: translateY(-1.5px);
    &:not(.primary) > i {
      color: $text-normal;
    }
  }
}
