.sum-charts {
  text.rv-xy-plot__axis__tick__text {
    font-size: x-small;
  }
  g text {
    &:first-child {
      transform: rotate(90deg);
    }
  }
}
