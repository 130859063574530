.counter {
  position: relative;
  top: 50%;
  left: 50%;
  /* color: #fff; */
  /* text-align: center; */
  transform: translateX(-50%) translateY(-50%);
}
/* .productDetailsCard {
    padding: 0 !important;
    margin: 0 !important;
  } */
.rowOfCart {
  margin: 0;
}
.buttonsOfCart {
  margin-top: 50px;
}
.activeStepper {
  background: rgb(33, 37, 41) !important;
  height: 40px !important;
  width: 40px !important;
}
/* .buttonsOfCart .trashButton {
    position: absolute !important;
    left: 0;
    bottom: 0;
  }
  .buttonsOfCart .chartButton {
    position: absolute;
    left: 0;
    bottom: 30px;
  } */
.RFS-ConnectorContainer {
  top: calc((2em - 1px) / 2) !important;
  left: calc((50% + 2em) - 8px) !important;
  right: calc((-50% + 2em) - 8px) !important;
  position: absolute !important;
}
.cartTable td {
  padding: 15px 0 15px 0;
}
.cartTable .headlineTr {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 121, 59, 1) 68%,
    rgba(0, 212, 255, 1) 100%
  );
  color: white;
}
.stepHeadline {
  background: rgb(33, 37, 41);
  color: white;
  margin: 0 !important;
}
span.RFS-Label {
  padding: 0 !important;
  margin: 0 !important;
}
.cartBtnLeft {
  color: #fff;
  text-align: center;
  position: relative;
  left: 0;
  background: linear-gradient(90deg, #05002478, #09793b 68%);
  color: white !important;
}
.cartBtnRight {
  color: #fff;
  text-align: center;
  position: relative;
  right: 0;
  background: linear-gradient(90deg, #05002478, #09793b 68%);
  color: white !important;
}

.BottomBtnCart {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 121, 59, 1) 68% /* rgba(0, 212, 255, 1) 100% */
  );
  color: white !important;
}

#reservationForm .md-form.form-group {
  margin-bottom: 2.5rem;
}
@media only screen and (max-width: 1000px) {
  .cartBtnRight,
  .cartBtnLeft {
    display: none !important;
  }
}
