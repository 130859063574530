$gray1: #fafafa;
$gray2: #f5f5f5;
$gray3: #ececec;
$gray4: #dedede;
$gray5: #d4d4d4;

$light-shadow: rgba(0, 0, 0, 0.1);
$medium-shadow: rgba(0, 0, 0, 0.2);
$hard-shadow: rgba(0, 0, 0, 0.3);

$user-color-1: #a4508b;
$user-color-2: #5f0a87;

$user-gradient: linear-gradient(326deg, $user-color-1 0%, $user-color-2 74%);

$text-light: #999;
$text-normal: #222;
