.tooltip2 {
  position: relative;
  display: inline-block;
}
.tooltiptext2 {
  font-size: 12pt !important;
}

.tooltip2 .tooltiptext2 {
  visibility: hidden;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -40px;
}

.tooltip2:hover .tooltiptext2 {
  visibility: visible;
}
