#apppage .gradient {
  background: -moz-linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  );
  background: -webkit-gradient(
    linear,
    45deg,
    from(rgba(42, 27, 161, 0.7)),
    to(rgba(29, 210, 177, 0.7))
  );
  background: -o-linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  );
}
#apppage .gradient {
  background: linear-gradient(
    45deg,
    rgba(42, 27, 20, 0.7),
    rgba(29, 100, 100, 0.7) 100%
  );
}

#apppage .view {
  /* background-image: url("../img/banner1.jpeg"); */
  background-image: url("../img/mixOfCherey.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
}
#myContainerFluid {
  background: url("../img/mixOfCherey.jpg") center center / cover no-repeat
    fixed;
}

#apppage h6 {
  line-height: 1.7;
}
#navFormLogo {
  width: 150px;
  background-color: transparent;
  background: transparent;
}
#navLogo {
  width: 80px;
  background-color: transparent;
  background: transparent;
}
li.nav-item {
  border: none !important;
  width: max-content !important;
}
.navbar {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
}
button.navbar-toggler {
  right: 0;
}

#meshekHeadline {
  display: none;
}
ul.navbar-nav.ml-auto.col-sm-8 {
  margin: auto;
}
ul.navbar-nav {
  margin: auto;
}
#userNameNav {
  background: none !important;
}
.navButton {
  border-radius: 5px !important;
}
.WelcomeImageCols h1,
.WelcomeImageCols h2,
.WelcomeImageCols h3,
.WelcomeImageCols .navButtonContainer {
  text-align: right;
  padding-right: 10%;
}
.navLogoBottom {
  display: none !important;
}
.mobileHr {
  height: 2px !important;
  border: 0 !important;
  margin: 5px !important;
  box-shadow: inset 0 10px 10px -10px #fff !important;
  display: none;
  width: 80%;
}
.navMobileIcon {
  display: none !important;
}
.dropdown .dropdown-content .mobileHrOfDropDown {
  display: none;
}
.navCloseBtn {
  display: none !important;
}
/* @media only screen and (max-width: 400px) {
    #apppage .animated img {
      max-height: 50vh;
      transform: translateX(-50%);
      left: 50%;
      position: absolute;
    }
  }
  @media only screen and (max-width: 767px) {
    #apppage .animated img {
      max-height: 35vh;
      transform: translateX(-50%);
      left: 50%;
    }
  }
  
  @media (max-width: 767px) and (orientation: landscape) {
    #apppage .animated img {
      max-height: 50vh;
      transform: translateX(-50%);
      left: 50%;
      position: relative;
    }
  }
  @media (max-width: 1000px) and (orientation: landscape) {
    #apppage .view {
      min-height: 150vh;
    }
  } */

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: right;
}

.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown:hover .dropdown-content {
  display: block;
}
@media only screen and (max-width: 767px) {
  li.nav-item {
    border-bottom: 1px solid white !important;
    width: 100% !important;
  }
  li.nav-item:last-child {
    border-bottom: none !important;
  }
  #meshekHeadline {
    display: block !important;
  }
  #meshekHeadline .homeNav {
    margin: auto !important;
  }
  li.nav-item {
    border-bottom: none !important;
  }
  .navbar.scrolling-navbar {
    text-align: center;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 121, 59, 1) 100%
    ) !important;

    /* background: url(/static/media/mixOfCherey.4f4f6ee5.jpg) 50% / cover
      no-repeat fixed !important; */
  }

  .navbar-nav {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  span.navbar-toggler-icon {
    font-size: inherit !important;
  }
  .WelcomeImageCols h1,
  .WelcomeImageCols h2,
  .WelcomeImageCols h3,
  .WelcomeImageCols .navButtonContainer {
    text-align: center !important;
    padding-right: 0 !important;
  }
  .navLogoBottom {
    position: relative !important;
    top: 25px;
    /* bottom: 20% !important; */
    display: block !important;
  }

  .mobileHr {
    display: block;
    margin-right: 10% !important;
  }
  .navLinkItem {
    text-align: right !important;
    margin-right: 9% !important;
  }
  .navMobileIcon {
    padding-right: 5px !important;
    display: inline !important;
  }
  .navLinkItem #userNameNav {
    text-align: center;
  }
  .dropdown:hover .dropdown-content {
    display: none;
  }
  .dropdown .dropdown-content {
    position: fixed;
    width: 80%;
    margin: auto;
    border: 1px solid;
    border-radius: 5px;
    margin-right: 10% !important;
    padding: 0 0 20% 0;
  }
  .dropdown .dropdown-content .mobileHrOfDropDown {
    height: 2px !important;
    border: 0 !important;
    margin: 5px !important;
    box-shadow: inset 0 10px 10px -10px black !important;
    width: 90%;
    display: block;
  }
  .dropdown-content a {
    color: black;
    padding: 2px 15px;
  }
  .navCloseBtn {
    display: block !important;
    position: absolute;
    padding: 5px 5px 0 0 !important;
    float: right;
    font-size: 20pt;
  }
}
