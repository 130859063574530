table {
  margin: auto;
  margin-bottom: 100px;
}

.ordersSummaryTable td,
.ordersSummaryTable th {
  border: 1px solid #dddddd;
  /* text-align: left; */
  /* padding: 8px; */
  font-size: 0.7rem !important;
  font-weight: 300 !important;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.tableOfOrders th {
  border: 1px solid #dbc6c6;
}
.boxDiv {
  /* background: white; */
  /* border: 1px solid black; */
  border-style: groove;
  border-radius: 5px !important;
}

.boxDiv span {
  position: relative;
  margin: auto;
  color: green;
}
