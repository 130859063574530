.form-lottie-wrapper {
  margin-top: -70px;
  .form-lottie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tomato-lottie svg {
      height: 150px !important;
    }
    .tractor-lottie svg {
      height: 250px !important;
    }
  }
  .lottie-col {
    margin: auto !important;
  }
}

@media only screen and (max-width: 667px) {
  .form-lottie-wrapper {
    margin-top: -50px;

    .form-lottie {
      justify-content: space-evenly !important;
      .tomato-lottie svg {
        height: 100px !important;
      }
      .tractor-lottie svg {
        height: 200px !important;
      }
    }
  }
}
