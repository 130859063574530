#headerOfForm {
  padding: 20px 10px;
  * {
    color: initial;
    font-weight: initial;
  }
  p {
    display: block !important;
  }
  strong {
    font-weight: bold;
    span {
      font-weight: bold;
    }
  }
}
.card-header.bg-dark.text-light.second-template {
  width: 100%;
  text-align: center;
  color: white !important;
}
.second-template {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 121, 59, 1) 68% /* rgba(0, 212, 255, 1) 100% */
  ) !important;
  color: white !important;
  font-weight: bold !important;
}
.admin-form {
  margin-top: 6%;
}
@media (max-width: 992px) {
  .admin-form {
    margin-top: 15%;
  }
}
