label {
  display: inline !important;
}
form {
  text-align: right !important;
  direction: rtl;
}
span {
  margin: 2% 0 2% 0;
}
