html {
  scroll-behavior: smooth;
}
body {
  direction: rtl;
  min-height: 100vh;
  /* font-family: "Bellefair", serif !important;
  font-size: la !important; */
}
/* This will work on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* #WelcomeImgTexts {
  padding-top: 2% !important;
} */
/* Targtes on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 121, 59, 1) 68%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
  border-radius: 20px;
  /* border: 3px solid orange; */
}

.lime.darken-4,
.bg-dark {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 121, 59, 1) 68%,
    rgba(0, 212, 255, 1) 100%
  );
}
.bg-info {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgb(13, 14, 13) 68%,
    rgb(22, 23, 24) 100%
  );
}
.firstDivAfterNav {
  padding-top: 8%;
  /* background: #fcf7d0; */
  background: #f5edb254;
  min-height: 100vh;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.lime.darken-4 {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 121, 59, 1) 68%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
}
.borderLine {
  border-bottom: 1px solid black;
}
h5.borderLine {
  border-bottom: 2px solid red;
}
img {
  border-radius: 0.25rem !important;
}
img:hover {
  opacity: 0.8;
  cursor: pointer;
}
.dinamicTrashBtn {
  justify-content: center;
  /* display: flex !important; */
  line-height: 1 !important;
  margin: 0 !important;
}
#shopingCartForm {
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 0;
  color: white;
  display: none;
}
button.submitBtn {
  border-radius: 5px;
}
.invalidProductHomePage {
  display: none;
}
.invalidProduct {
  cursor: not-allowed !important;
}
.isNotActiveInAdmin {
  background-color: #dddddd !important;
}
.activeProductInAdminProducts {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.invalidProduct td {
  text-decoration: line-through;
}
.statistics {
  display: inline-block;
}
.invalidProduct h6 {
  /* text-decoration: line-through; */
  float: right;
}
.invalidProduct h4 {
  text-decoration: line-through;
}

.navbar.navbar-dark .breadcrumb .nav-item.active > .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item.active > .nav-link {
  background-color: inherit;
}
a.nav-link.Ripple-parent.active {
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: bold;
}
a.nav-link.Ripple-parent.homeNav:not(:active) {
  background-color: inherit;
}
.modal-body,
.modal-header {
  margin: auto;
  text-align: center;
}
.modal-footer {
  margin: auto;
}

.submitBtn {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 121, 59, 1) 68% /* rgba(0, 212, 255, 1) 100% */
  );
  color: white !important;
  font-weight: bold !important;
}
#floatedBtnOnTable {
  position: absolute;
  right: 60%;
  top: 50%;
  left: 0;
  display: none;
}
#developerNavItem,
#navLogoForSmallScreen {
  display: none;
}

#myNav {
  z-index: 3;
  /* background: inherit; */
}
.quantity {
  z-index: 1;
}
.md-form .prefix {
  width: inherit;
}
ol.carousel-indicators {
  z-index: 1;
}
a.carousel-control-next {
  z-index: 1;
}
a.carousel-control-prev {
  z-index: 1;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.verticalLine {
  border-left: 3px solid black;
  height: 50px;
}

.dropdown-menu.show {
  display: block;
}
form i.fa.fa,
.comment-section i {
  left: 0 !important;
}
form {
  background: white;
}
input[type="file"] {
  width: 100%;
}
.form-control:disabled,
.dinamicTrashBtn:disabled,
button:disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}
.form-group.statistics > input,
.form-group.statistics h6 {
  text-align: center !important;
  margin: auto !important;
}
#dataTableForForm td {
  text-align: center;
}

#dataTableForForm .firstTd {
  background: white !important;
}

#dataTableForForm th {
  vertical-align: middle;
  text-align: center !important;
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

#dataTableForForm span {
  /* font-family: cursive; */
  font-size: 14pt;
}
/* table {
  /* white-space: nowrap !important; */
/* overflow-x: hidden;
  overflow-y: hidden; */
/* } */
*/ .roudedButtonsAboveTable .tooltip2 {
  margin-left: 5px !important;
}
.roudedButtonsAboveTable .tooltip2 button {
  display: inline !important;
}
.roudedButtonsAboveTable .rounded-button {
  display: inline !important;
  margin-left: 5px;
}

.toolTipInTable .tooltip2 {
  display: inherit;
}
.toolTipInTable .tooltip2 button {
  margin: 2px;
}
.separatedRow {
  height: 50px;
  background-color: rgb(252, 247, 208) !important;
}
.separatedRow:hover {
  background-color: none !important;
}
.table-responsive {
  width: 98% !important;
  overflow: hidden !important;
  margin: auto !important;
}
#dataTable td,
#dataTableForForm td,
#summaryTable td {
  border-top: 1px solid #343a40 !important;
  border: none;
  text-align: center;
}
#dataTableForForm td {
  width: 30px;
  overflow: hidden;
}
#summaryTable .summaryTableDatalRow {
  background-color: white !important;
}
#summaryTable .summaryTableSumRow {
  /* font-family: italic; */
  color: black;
  font-size: xx-large;
  background-color: #dddddd;
}

.tableOfOrders td {
  border: 1px solid #dbc6c6 !important;
}
.summaryTableSumRow td {
  font-size: 1rem;
}
#dataTable tbody tr:hover {
  /* background-color: #7272fe80 !important; */
  /* color: #a6c !important; */
  background-color: transparent !important;
  border: 1.5px solid #a6c;
  /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important; */
  cursor: pointer;
}
#dataTable thead tr:hover {
  border: 1.5px solid black !important;
}
#summaryTable .summaryTableDatalRow:hover {
  background-color: transparent;
  border: 2px solid #a6c !important;
  /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important; */
  cursor: pointer;
}

#dataTable tbody tr:hover > #floatedBtnOnTable {
  display: block !important;
}
#sumRow:hover {
  cursor: unset !important;
  border-top: 3px solid black !important;
  border-bottom: black !important;
  border-left: black !important;
  border-right: black !important;
}

#dataTable tbody tr:hover a {
  color: black !important;
}
.reservationTable tr:hover td {
  background: none;
  color: none;
}
.reservationTable tr:hover td a {
  background: none;
  color: none;
}

#dataTable tr:hover a,
#dataTable tr:hover td {
  /* font-weight: bold; */
  color: black;
}
.reservationTable td {
  border: 1px solid black !important;
}
.notHasHoverAttributes {
  background: transparent !important;
  color: black !important;
}
.notHasHoverAttributes:hover {
  background: transparent !important;
  color: black !important;
}
#dataTable tr td + button {
  background-color: red !important;
}

.staticTopRowTable {
  display: block;
  max-height: 550px;
  overflow-y: scroll;
}
.staticTopRowTable th {
  position: sticky !important;
  top: 0 !important;
  z-index: 2;
  /* border: none; */
}

#dataTable td:first-child:hover,
#dataTable td:last-child:hover {
  color: black !important;
  background-color: transparent;
}
.mobile-break {
  display: none;
}
/* .headlineRow:hover {
  background-color: black !important;
} */
/* #totalPriceInput {
  margin: auto !important;
  display: inline;
} */
#headerOfForm p,
#totalPriceInput {
  /* width: 100%;  */
  display: inline-block;
  margin: auto;
  text-align: right;
}
/* קוד לשורה עליונה קפואה בעמוד סיכום הזמנות */
/* #dataTable {
  table-layout: fixed;
  border-collapse: collapse;
}

#dataTable tbody {
  display: inline-block;
  width: 100%;
  overflow: auto;
  height: 400px;
}

#dataTable thead tr {
  display: block;
}
#dataTable th,
#dataTable td {
  /* padding: 5px; */

/* width: 200px !important;
  overflow: hidden !important;
} */
/* */
/* .reservationTable */
/* { */
*/
  /* font-family: cursive; */
/* } */

label#cash:hover,
img.img-fluid:hover {
  cursor: pointer !important;
}
input[type="radio"]:hover,
input[type="checkbox"]:hover {
  cursor: pointer !important;
}
.myCheckbox:hover {
  cursor: pointer !important;
}
#myCheckbox:hover {
  cursor: pointer !important;
}
.rounded-button {
  transition: all 0.1s;
  height: 35px;
  width: 35px;
  border-radius: 20px;
  border: none;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
  background: white;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
}
.sticky-column {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  background: rgb(221, 221, 221);
  color: black;
  font-weight: bold;
  z-index: 1;
  width: 100px; /* How can I make this dynamic? */
}
.sticky-columnForOrders {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  background: azure;
  color: black;
  font-weight: bold;
  z-index: 1;
  /* width: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 60px; */
  height: 80px;
}
td.sumOfRevenuesTd,
th.sumOfRevenuesTd {
  border-left: 1px solid black !important;
  font-weight: bold;
}
.qty {
  background: none !important;
}
.qty .disabled,
:disabled {
  display: inline-block !important;
}
.rounded-button i {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a6c !important;
}

table .rounded-button i {
  color: #999;
}
table td,
th {
  vertical-align: middle !important;
  text-align: center !important;
}
#clipBoard:hover {
  font-size: 12pt;
  cursor: -webkit-grab;
  cursor: grab !important;
}
.notInForm {
  background-color: #dddddd !important  ;
}
td.myCell {
  color: black;
}

button.btn-secondary {
  font-weight: bold;
  margin: 0 0 0 10px auto;
  border-radius: 5px;
}
.inForm {
  color: #a6c !important;
  background-color: transparent !important;
  border: 2px solid #a6c !important;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  border-radius: 5px;
}
.page-item {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-item:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
/* .md-form label.active {
  transform: translateY(-25px) scale(0.8);
} */
form input,
textarea {
  background-color: transparent !important;
  border: 2px solid !important;
  border-radius: 5px !important;
  padding-right: 2% !important;
}
/* .divOfInput {
  width: 95% !important;
  background-color: transparent;
  border: 2px solid;
  border-radius: 5px !important;
  padding-right: 2% !important;
} */
.divOfInputPlacesClass,
.divOfInputPaymentClass {
  width: 95%;
  box-shadow: 0 1px 0 0 #4285f4;
  border: 3px solid #4285f4;
  color: #4285f4;
  background-color: transparent;
  border-radius: 5px;
  padding-right: 2%;
}

form input:focus,
textarea:focus {
  border: 3px solid #a6c !important;
}
.md-form .prefix ~ label {
  margin-left: -10px !important;
}
form .md-form label.active {
  transform: translateY(-24px) scale(0.8) !important;
}
.rowClicked {
  color: #a6c !important;
  background-color: transparent !important;
  border: 2px solid #a6c !important;
}

.loginFields {
  direction: ltr;
}
.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}
.passwordIcon {
  position: absolute;
  top: 38%;
  right: 2%;
  cursor: pointer;
}
.passwordIcon:hover {
  color: #4285f4;
}
.almogBtn {
  font-weight: bold !important;
  color: #a6c !important;
  background-color: white !important;
}
.almogBtn a {
  color: #a6c !important;
}
.iconsInLastPageOfForm i {
  font-size: 25pt !important;
}
.iconsInLastPageOfForm img {
  width: 40px !important;
}
.inputOfRevenue {
  text-align: center;
  width: 80%;
  border-radius: 5px;
}
.inputOfRevenue::-webkit-outer-spin-button,
.inputOfRevenue::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputOfRevenue[type="number"],
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"] {
  width: 70px !important;
}

@media print {
  @page {
    size: A4 landscape;
    margin: 20mm;
    position: absolute;
    width: 100%;
    background: transparent;

    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    /* filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); */
  }
  body {
    /* writing-mode: tb-rl; */
    writing-mode: vertical-rl;
  }

  table {
    table-layout: fixed; /* makes the text-overflow function with percentage widths on the td or th */
    width: 100%;
    border-collapse: collapse;
    display: block;
  }

  td {
    width: 18%; /* will be changed to 10% for the first column which gives 10 + 18*5 = 100% */
    padding: 0 1mm 0 1mm;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  tr,
  th,
  td {
    border: 2px solid black;
    color: black !important;
  }

  @page :left {
    margin-left: 1cm;
  }

  @page :right {
    margin-left: 1cm;
  }
  table {
    table-layout: fixed;
    padding: 0;
    margin: 0;
    /* border-collapse: collapse; */
  }
  /* table tr > td:nth-child(2),
  table tr > td:nth-child(3), */
  table tr > td:nth-child(1),
  table tr > td:nth-child(5) {
    display: none;
  }
  /* table tr > th:nth-child(2),
  table tr > th:nth-child(3), */
  table tr > td:nth-child(1),
  table tr > th:nth-child(5) {
    display: none;
  }
  table td {
    height: 50px !important;
  }
}
#introduction,
#contact {
  padding-top: 0;
  min-height: 600px;
}
.reactShareIcons:hover {
  opacity: 0.75;
}
.li.nav-item {
  white-space: nowrap !important;
}
a.nav-link.Ripple-parent {
  white-space: nowrap !important;
}
.WelcomeImageCols {
  padding-top: 5%;
}
.WelcomeImageCols:nth-child(2) {
  padding-top: 8%;
}
.propertyOfProduct {
  padding-right: 0 !important;
}
.filter-actions {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media (max-width: 992px) {
  .sticky-columnForOrders {
    position: sticky;
    position: -webkit-sticky;
    right: 0;
    background: azure;
    color: black;
    font-weight: bold;
    z-index: 1;
    /* width: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 80px;
  }

  /* .navbar .container {
    background: linear-gradient(
      45deg,
      rgba(42, 27, 20, 0.7),
      rgba(29, 100, 100, 0.7) 100%
    );
  } */
  .WelcomeImageCols:nth-child(2) {
    display: none;
  }
  .navbar.navbar-dark .breadcrumb .nav-item.active > .nav-link,
  .navbar.navbar-dark .navbar-nav .nav-item.active > .nav-link {
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 121, 59, 1) 68%,
      rgba(0, 212, 255, 1) 100%
    );
  }
  .mr-auto {
    width: 20% !important;
  }
  .mr-auto,
  .mx-auto {
    margin-right: auto !important;
    width: 20% !important;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .fixed-top {
    padding-top: 0;
  }
  .navbar {
    position: relative !important;
  }
  /* .col-md-6.white-text.text-center.text-md-right.mt-xl-5.mb-5 {
        margin-top: 100px;
        padding-top: 100px;
        height: 500px;
        padding-bottom: 10px;
    } */
  #apppage .view {
    min-height: 100vh;
    padding-top: 150px !important;
    height: 100%;
    padding-bottom: 10px;
  }
  .col-md-6.white-text.text-center.text-md-right.mt-xl-5.mb-5 {
    padding-top: 100px;
  }
  #apppage .view .carousel.carousel-fade.z-depth-1 {
    display: none;
  }
  img.d-block.w-100 {
    height: 300px;
    margin: auto;
    width: inherit !important;
  }
  .container-fluid {
    min-height: 100vh !important;
  }
  .navbar {
    position: fixed !important;
  }
  /* .navbar-nav {
    padding-left: 50px !important;
  } */
  button.navbar-toggler:focus {
    background-color: white;
  }
  nav.navbar-dark.fixed-top.scrolling-navbar.navbar.navbar-expand-md {
    padding: 0;
  }
  .navbar-dark .navbar-brand {
    margin: auto;
    text-align: center;
    width: 100%;
  }
  img.img-responsive {
    width: 50%;
    height: inherit;
  }
  button.navbar-toggler:focus {
    background-color: transparent;
  }
  li.nav-item {
    width: 100%;
    margin: auto;
    border-bottom: 1px solid white;
    text-align: center;
  }
  a.nav-link.Ripple-parent.active {
    background-color: inherit;
    width: 80%;
  }
  .navbar.navbar-dark .navbar-toggler {
    float: right;
    top: 0;
    position: absolute;
  }
  #navLogo {
    /* bottom: 0;
        position: absolute;
        left: 0;
        width: 60px;
        background-color: transparent;
        background: transparent; */
    display: none;
  }
  #developerNavItem {
    display: inline;
  }
  #developerNavbar {
    display: none;
  }
  #navLogoForSmallScreen {
    display: block;
    width: 50px;
    float: left;
    height: 50px;
    position: absolute;
    left: 0;
  }
  .navbar-brand {
    padding: 0 !important;
  }
  #navLogoForSmallScreen img {
    height: 50px;
  }
  .firstDivAfterNav {
    margin-top: 10%;
  }
  #floatBtnReservation {
    height: 60px;
  }

  ul.navbar-nav.justify-content-around.w-100 {
    height: 50px;
  }

  h2 {
    font-size: larger !important;
  }
  h3,
  h4,
  h5,
  #totalH3 {
    font-size: large !important;
  }
  label {
    font-size: smaller !important;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="date"],
  input[type="time"],
  input[type="link"],
  input[type="number"],
  input[type="file"],
  textarea {
    width: 100% !important;
  }

  form i,
  .formIcon {
    font-size: 14pt !important;
    top: 35% !important;
    left: 5px !important;
  }
  #productsTable td,
  th {
    font-size: 8pt !important;
  }
  #dataTableForForm {
    width: 100% !important;
  }
  #dataTableForForm td,
  span,
  #dataTable td,
  #summaryTable td {
    font-size: 8pt !important;
    font-weight: bold;
  }
  #dataTable .rounded-button {
    max-width: max-content;
  }
  .rounded-button i {
    font-size: 8pt;
  }
  button.rounded-button {
    /* display: contents; */
    font-size: small;
  }
  #dataTable,
  #summaryTable {
    table-layout: initial !important;
  }
  #dataTable button.rounded-button {
    display: contents !important;
  }
  .tooltip2 {
    display: inline-flex;
  }

  table {
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: inherit !important;
    table-layout: auto;
  }
  table h4#prodName {
    font-size: 8pt !important;
  }
  table td h6 {
    font-size: 8pt !important;
  }
  .cellHiddenInMobile {
    display: none;
  }
  table img.img-responsive {
    width: 50%;
  }
  .table-responsive {
    width: 100% !important;
    overflow: auto !important;
  }
  #productDescriptionForAdmin {
    font-size: 6pt !important;
  }
  .quantityContainer {
    display: inherit;
  }
  .quantityContainer .rounded-button {
    display: block;
    width: 50px;
  }
  .toolTipInTable .tooltip2 i {
    margin: 2px;
  }
  .inputOfRevenue {
    font-size: 5pt;
    width: 200px !important;
  }
  .dinamicTrashBtn {
    width: min-content;
  }
  /* .iconsInLastPageOfForm i {
    font-size: 14pt !important;
  }
  .iconsInLastPageOfForm img {
    width: 20px !important;
  } */
  .propertyOfProduct {
    padding: 0 !important;
  }
  .divOfInputPlacesClass,
  .divOfInputPaymentClass {
    width: 100% !important;
  }
  .h1-responsive {
    padding: 5px;
  }
  .mobile-break {
    display: block;
  }
  #headerOfForm p {
    width: 100%;
  }

  nav.react-contextmenu.ui.vertical.menu.react-contextmenu--visible,
  .react-contextmenu.react-contextmenu--visible {
    display: none !important;
  }
  .lime.darken-4 {
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 121, 59, 1) 100%
    ) !important;
  }
  i.fa.fa-home.prefix {
    margin-top: -10px !important;
  }
  .filter-actions {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
