td a {
  color: #007bff !important;
}
#WelcomeImg {
  display: none;
}
#shopingCart {
  display: none !important;
}
#myNav {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 121, 59, 1) 68%,
    rgba(0, 212, 255, 1) 100%
  );
}
.headlineRow {
  border-bottom: 2px solid black !important;
}
.headlineRow:hover {
  background-color: #e0e0e0 !important;
}
.firstDivAfterNavOfLogin {
  margin-top: 7% !important;
}
.isNotActive {
  color: rgb(175, 107, 107) !important;
  /* background-color: transparent !important; */
  /* border: 2px solid rgb(194, 144, 144) !important; */
  /* box-shadow: rgb(0 0 0 / 50%) 5px 5px 5px 5px; */
  border-radius: 5px;

  /* background-color: rgb(194, 144, 144) !important; */
}
.isNotActive button i {
  color: rgb(175, 107, 107) !important;
}
.isNotActive a {
  color: rgb(175, 107, 107) !important;
}

@media only screen and (max-width: 600px) {
  .collapse.show.navbar-collapse {
    height: 100vh !important;
  }
  .firstDivAfterNavOfLogin {
    margin-top: 10% !important;
  }
  .firstDivAfterNavOfLogin input[type="text"],
  .firstDivAfterNavOfLogin input[type="password"] {
    width: 80% !important;
    /* margin: auto; */
  }
  .firstDivAfterNavOfLogin i {
    font-size: 14pt !important;
    left: 20px !important;
  }
}
