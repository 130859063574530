body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Arimo", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.container-fluid {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  overflow: hidden;
}

.container-fluid:nth-child(even) {
  /* background-color: #fcf7d0; */
  background: #f5edb254;
}
h2,
.myRedText {
  color: #827717;
}

h1 {
  text-shadow: 1px -1px 0 #ffffff, 1px -1px;
}
@media only screen and (max-width: 767px) {
  h1 {
    text-shadow: none !important;
  }
}
