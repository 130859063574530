.spinnerContainer {
  /* margin: auto; */
  text-align: center;
  position: relative;
  background: white;
}
.spinnerContainer .spinnerContent {
  text-align: center;
  position: relative;
}
.spinner-border.text-dark {
  margin: auto;
  z-index: 2 !important;
  width: 50px;
  height: 50px;
}
