.preparation-order {
  .header {
    background: #3b0b0b0a;
    border-bottom: 1px solid;
  }
  .part-of-order {
    font-size: 20px;
  }
  .product-wrapper {
    height: 60px;
    p {
      font-size: 1.5em;
    }
    .flex-row {
      border-bottom: 1px solid;
      margin: 5px 0;
      img {
        flex: 1;
        max-height: 55px;
        max-width: 60px;
      }
      p {
        flex: 3;
        font-weight: initial !important;
        margin-top: 5px !important;
        text-align: right;
        margin-right: 50px;
      }
    }
    .active {
      border: 2px solid #a6c;
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
      border-radius: 5px;
      padding: 0 30px;
      // background-color: transparent;
      // color: #a6c;
      background: linear-gradient(90deg, rgb(16 6 187) 0%, rgb(9, 121, 59));
      color: white;
    }
    .not-active {
      background-color: #fff;
      padding: 0 30px;
    }
  }
  i.fa.fa-pen.prefix {
    left: 5px;
    top: 10px;
  }
  #seconds {
    font-size: 20px !important;
  }
  h3 {
    font-size: 2.5em !important;
  }
  i.fa.fa-solid.fa-quote-right,
  i.fas.fa-edit {
    font-size: 14px;
  }

  .order-ready-wrapper {
    background-color: white;
    width: 100%;
    height: 60px;
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
  }
  .fas.fa-check {
    font-size: 80px;
  }
  .small-checked {
    font-size: 5px !important;
  }
  .spinnerContent {
    position: fixed;
    margin: auto;
    display: flex;
    left: 50%;
    top: 50%;
    opacity: 0.5;
  }
  .circles-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
    justify-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    margin-top: 2em;
    background-color: #fff;
    .circle {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin: 5px 0 0 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    }
    .active-circle {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin: 5px 0 0 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black !important;
      font-weight: bold;
      border: 1px solid #d12829;
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    }
    .circle:last-child {
      .rtl & {
        margin-left: 10px;
      }
      .ltr & {
        margin-right: 10px;
      }
    }
  }
  .dropdown-content {
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: #dedede;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    i.fas.fa-times {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 20px;
    }
    button {
      margin: 0 15px !important;
      a {
        padding: 0 !important;
      }
    }
  }
  // .new-reservation {
  //   position: fixed;
  //   left: 0;
  //   bottom: 0;
  // }
}
table#dataTable {
  // margin: 10px !important;
}
.preparation-list {
  .input-search {
    margin: 5px auto auto auto;
  }
  .firstDivAfterNavOfLogin i {
    font-size: 10px !important;
  }
  .active {
    border: 2px solid #a6c;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 5px;
    padding: 0 30px;
    // background-color: transparent;
    // color: #a6c;
    background: linear-gradient(90deg, rgb(16 6 187) 0%, rgb(9, 121, 59));
    color: white;
    border-bottom: 1px solid #e6d9d9;
  }
  .not-active {
    background-color: #fff;
    padding: 0 30px;
    border-bottom: 1px solid #e6d9d9;
  }
  p {
    font-size: 30px;
  }
}
.preparation-order-name-list-modal {
  width: 100%;
  .modal-content {
    height: max-content;
    min-height: 100vh;
    padding: 0 15px;
    p {
      margin: 5px 0 !important;
      border-bottom: 1px solid #e6d9d9;
      width: 100%;
    }
    i.fas.fa-times {
      position: absolute;
      left: 0;
      font-size: 20px;
    }
  }
}
