.form-switch {
  justify-content: center;
  align-items: center;
  .ltr & {
    margin-left: 5px;
  }
  .rtl & {
    margin-right: 5px;
  }

  //.form-check-input:disabled {
  //  cursor: not-allowed;
  //}
  .custom-control-label {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 800px) {
  .form-switch {
    justify-content: space-between !important;
  }
}
